var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-height align-start",
      class: _vm.$config("app.layoutColors.containerBackground")
    },
    [
      _vm.loading ? _c("app-loader") : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ]
        },
        [
          _c("app-header", {
            on: {
              toggleNav: function($event) {
                _vm.drawer = !_vm.drawer
              },
              mounted: function($event) {
                _vm.initialization.header = true
              }
            }
          }),
          _c("app-sidebar", {
            attrs: { drawer: _vm.drawer },
            on: {
              "update:drawer": function($event) {
                _vm.drawer = $event
              },
              mounted: function($event) {
                _vm.initialization.sidebar = true
              }
            }
          }),
          _vm.initialized
            ? _c(
                "v-main",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "vue-page-transition",
                                { attrs: { name: "fade-in-up" } },
                                [
                                  _c(
                                    "keep-alive",
                                    [
                                      _vm.$route.meta.keepAlive && !_vm.loading
                                        ? _c("router-view", {
                                            staticClass: "py-3"
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "vue-page-transition",
                                { attrs: { name: "fade-in-up" } },
                                [
                                  !_vm.$route.meta.keepAlive
                                    ? _c("router-view", { staticClass: "py-3" })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("app-footer", {
            on: {
              mounted: function($event) {
                _vm.initialization.footer = true
              }
            }
          })
        ],
        1
      ),
      _c("psi-snackbar", { attrs: { timeout: 6000 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }