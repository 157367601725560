<template>
    <div
        :class="$config('app.layoutColors.containerBackground')"
        class="fill-height align-start"
    >
        <app-loader v-if="loading"></app-loader>
        <!-- <psi-app-loader v-if="loading"></psi-app-loader> -->
        <div v-show="!loading">
            <app-header
                @toggleNav="drawer = !drawer"
                @mounted="initialization.header = true"
            ></app-header>
            <app-sidebar
                :drawer.sync="drawer"
                @mounted="initialization.sidebar = true"
            ></app-sidebar>
            <v-main v-if="initialized">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <vue-page-transition name="fade-in-up">
                                <keep-alive>
                                    <router-view
                                        class="py-3"
                                        v-if="$route.meta.keepAlive && !loading"
                                    ></router-view>
                                </keep-alive>
                            </vue-page-transition>

                            <vue-page-transition name="fade-in-up">
                                <router-view
                                    class="py-3"
                                    v-if="!$route.meta.keepAlive"
                                ></router-view>
                            </vue-page-transition>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
            <app-footer @mounted="initialization.footer = true"></app-footer>
        </div>
        <!-- We need the snackbar to post any errors during login -->
        <psi-snackbar :timeout="6000"></psi-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "auth-layout",
    components: {
        AppHeader: () => import("./AppHeader"),
        AppSidebar: () => import("./AppSidebar"),
        AppFooter: () => import("./AppFooter"),
        AppLoader: () => import("./components/ApplicationStartLoader.vue"),
    },
    data: () => ({
        drawer: null,
        initialization: {
            header: false,
            sidebar: false,
            footer: false,
        },
    }),
    computed: {
        ...mapGetters("Users", ["loggedIn"]),
        ...mapGetters(["loading"]),
        initialized() {
            return Object.keys(this.initialization).every((key) => {
                return this.initialization[key];
            });
        },
    },
    created() {
        this.getAuthenticatedUser();
    },
    mounted() {},
    methods: {
        ...mapActions("Users", ["getAuthenticatedUser"]),
    },
};
</script>

<style>
.v-navigation-drawer__border {
    display: none;
}
.slide-leave-active {
    transition: 0.1s;
    opacity: 0;
}
.slide-enter-active {
    transition: 0.9s;
    opacity: 1;
}
.slide-enter {
    transform: translateY(100%);
}
.slide-leave-to {
    transform: translateY(50%);
}
</style>

